import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import ToolbarActions from '../ToolbarActions'
import AirtableIcon from '@/assets/images/icons/airtable.svg?react'
import DjangoIcon from '@/assets/images/icons/django.svg?react'
import GitHubIcon from '@/assets/images/icons/github.svg?react'
import ImageIcon from '@/assets/images/icons/image.svg?react'
import WebflowIcon from '@/assets/images/icons/webflow.svg?react'
import { Container } from './styles'

const ModuleTab = () => {
  const { currentModule, toolbar } = useSelector((state) => state.modules)

  return (
    <Container className="module-tab">
      <div className="module-info">
        <h5 className="title">{currentModule?.name}</h5>

        <div className="lab-stats">
          <div className="lab-stat">
            <p className="title">
              {toolbar?.published_at ? dayjs(toolbar?.published_at).format('LL') : 'Not published'}
            </p>
            <p className="subtitle">Published date</p>
          </div>

          <div className="lab-stat">
            <p className="title">{toolbar?.total_finished} users</p>
            <p className="subtitle">Finished this project</p>
          </div>
        </div>

        <div className="issues-info">
          {!!toolbar?.user_issues?.open && (
            <a className="issue-info" href={toolbar?.user_issues?.github_issues_url} target="_blank" rel="noreferrer">
              {toolbar?.user_issues?.open} open issues
            </a>
          )}

          {!!toolbar?.user_issues?.total && (
            <a className="issue-info" href={toolbar?.user_issues?.github_issues_url} target="_blank" rel="noreferrer">
              {toolbar?.user_issues?.total} issues created
            </a>
          )}
        </div>

        <div className="links-info">
          <Tooltip title={toolbar?.www_url ? 'Webflow public URL' : 'Missing Webflow public URL'}>
            <a
              className={`link ${toolbar?.www_url || 'error'}`}
              href={toolbar?.www_url || null}
              target="_blank"
              rel="noreferrer"
            >
              <WebflowIcon />
            </a>
          </Tooltip>

          <Tooltip title={toolbar?.airtable_url ? 'Project has an Airtable row' : 'Missing Airtable row'}>
            <a
              className={`link ${toolbar?.airtable_url || 'error'}`}
              href={toolbar?.airtable_url || null}
              target="_blank"
              rel="noreferrer"
            >
              <AirtableIcon />
            </a>
          </Tooltip>

          <Tooltip title={toolbar?.django_admin_url ? 'Django Admin' : 'Missing Django Admin'}>
            <a
              className={`link ${toolbar?.django_admin_url || 'error'}`}
              href={toolbar?.django_admin_url || null}
              target="_blank"
              rel="noreferrer"
            >
              <DjangoIcon />
            </a>
          </Tooltip>

          <Tooltip title={toolbar?.github_repo_url ? 'GitHub Repo' : 'Missing GitHub repo'}>
            <a
              className={`link ${toolbar?.github_repo_url || 'error'}`}
              href={toolbar?.github_repo_url || null}
              target="_blank"
              rel="noreferrer"
            >
              <GitHubIcon />
            </a>
          </Tooltip>

          <Tooltip title={toolbar?.svg_icon_url ? 'Project has SVG icon' : 'Missing project SVG icon'}>
            <a
              className={`link ${toolbar?.svg_icon_url || 'error'}`}
              href={toolbar?.svg_icon_url || null}
              target="_blank"
              rel="noreferrer"
            >
              <ImageIcon />
            </a>
          </Tooltip>
        </div>
      </div>

      {!!toolbar?.errors?.length && (
        <div className="errors-container">
          {toolbar?.errors?.map((e, i) => (
            <div key={i} className="error-content">
              <span className="bullet" />

              <div className="error-info">
                <p className="title">{e}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <ToolbarActions />
    </Container>
  )
}

export default ModuleTab
