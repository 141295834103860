import styled from 'styled-components'

export const Container = styled.div`
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .og-image {
      min-height: 160px;
      max-width: 340px;
      width: 100%;
      background: ${(props) => props.theme.colors.gray5};
      border-radius: 10px;
      margin-top: 20px;
    }
  }

  .option-container {
    width: 100%;
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 20px 0px;

    > .content {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .option-text {
        font-weight: 500;
        margin: 0px;
      }
    }
  }

  .error-text {
    color: ${(props) => props.theme.colors.red4};
    font-size: 12px;
    margin: 20px 0px 0px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 14px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
    padding: 20px 0px;

    .action-item {
      width: 100%;

      > button {
        width: 100%;
      }

      .info-text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
      }
    }

    .beta-testers-list {
      .with-group {
        color: ${(props) => props.theme.colors.green7};
      }

      .without-group {
        color: ${(props) => props.theme.colors.gold7};
      }

      .not-found {
        color: ${(props) => props.theme.colors.volcano7};
      }
    }
  }
`
