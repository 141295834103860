import { useSelector, useDispatch } from 'react-redux'
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from 'antd'
import { getUtmTagsFromUrl } from '@/helpers/getUtmTagsFromUrl'
import { authWithGoogle } from '@/store/users/actions'
import GoogleIcon from '@/assets/images/icons/google.svg?react'
import { Container } from './styles'

const OauthBox = ({ action, text, data }) => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state.users)

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      const auxData = {
        code: codeResponse?.code,
        metadata: getUtmTagsFromUrl(),
        action,
        data,
      }

      await dispatch(authWithGoogle(auxData))
    },
  })

  return (
    <Container id="oauth-box">
      <Button className="sign-in-btn" size="large" onClick={() => googleLogin()} loading={isLoading}>
        <GoogleIcon /> {text || 'Continue with Google'}
      </Button>

      <div className="oauth-divider">
        <span className="separator">or use your credentials</span>
      </div>
    </Container>
  )
}

export default OauthBox
