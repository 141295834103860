import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Tooltip, Switch } from 'antd'
import Button from '@/components/Button'
import {
  getModuleToolbar,
  updateModule,
  importModule,
  regenerateOGImage,
  importModuleToWebflow,
  syncModuleBetaTesters,
} from '@/store/modules/actions'
import { pullLabImages } from '@/store/labs/actions'
import { Container } from './styles'

const ToolbarActions = () => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, toolbar, betaTestersData } = useSelector((state) => state.modules)

  const canChangeModule = userProfile?.permissions?.includes('content.change_module')
  const canRunImportModule = userProfile?.permissions?.includes('content.run_import_module')
  const canRunPullImages = userProfile?.permissions?.includes('labs.run_pull_images')
  const canRegenerateOGImage = userProfile?.permissions?.includes('content.regenerate_og_image')
  const canRunWebflowUpsert = userProfile?.permissions?.includes('content.webflow_upsert')
  const canSyncBetaTesters = userProfile?.permissions?.includes('accounts.sync_beta_testers')
  const showActions = canRunImportModule || canRunPullImages || canRegenerateOGImage || canRunWebflowUpsert

  const [isLoading, setIsLoading] = useState(false)
  const [showOGImageInfo, setShowOGImageInfo] = useState(false)
  const [error, setError] = useState()

  const handleSolutionsSet = async (value) => {
    setIsLoading(true)
    setError()
    const response = await dispatch(updateModule(currentModule?.id, { show_solutions: value }))

    if (response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)
  }

  const handleModuleImport = async () => {
    setIsLoading(true)
    setError()
    const response = await dispatch(importModule(currentModule?.github_repo_url))

    if (!response?.sucess && response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)
  }

  const handlePullLabImages = async () => {
    setIsLoading(true)
    setError()
    const response = await dispatch(pullLabImages(currentModule?.lab_id))

    if (!response?.sucess && response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)
  }

  const handleRegenerateOGImage = async () => {
    setIsLoading(true)
    setError()
    const response = await dispatch(regenerateOGImage(currentModule?.id))

    if (!response?.sucess && response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)

    if (response?.success) {
      setShowOGImageInfo(true)

      setTimeout(() => {
        dispatch(getModuleToolbar(currentModule?.id))
        setShowOGImageInfo(false)
      }, 10000)
    }
  }

  const handleModuleToWebflow = async () => {
    setIsLoading(true)
    setError()
    const response = await dispatch(importModuleToWebflow(currentModule?.id))

    if (!response?.sucess && response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)
  }

  const handleSyncBetaTesters = async () => {
    setIsLoading(true)
    setError()
    const response = await dispatch(syncModuleBetaTesters())

    if (!response?.sucess && response?.error) {
      setError(response?.error)
    }
    setIsLoading(false)
  }

  return (
    <Container className="toolbar-actions">
      <div className="image-container">
        <img className="og-image" src={toolbar?.og_image_urls?.module_default} alt="OG Image" />
      </div>

      {canChangeModule && (
        <>
          <div className="option-container">
            <div className="content">
              <Switch
                onChange={handleSolutionsSet}
                loading={isLoading}
                defaultChecked={currentModule?.show_solutions}
              />
              <p className="option-text">Show Solutions</p>
            </div>
          </div>
        </>
      )}

      {error && <p className="error-text">{error}</p>}

      {showActions && (
        <div className="actions">
          {canRunImportModule && (
            <div className="action-item">
              <Button type="primary" onClick={handleModuleImport} loading={isLoading}>
                Trigger project import
              </Button>

              {currentModule?.last_imported_at && (
                <Tooltip title={dayjs(currentModule?.last_imported_at).utc().format('lll')} placement="right">
                  <p className="info-text">Last imported {dayjs(currentModule?.last_imported_at).fromNow()}</p>
                </Tooltip>
              )}
            </div>
          )}

          {canRunPullImages && (
            <div className="action-item">
              <Button type="primary" onClick={handlePullLabImages} loading={isLoading}>
                Trigger image pull
              </Button>
            </div>
          )}

          {canRegenerateOGImage && (
            <div className="action-item">
              <Button type="primary" onClick={handleRegenerateOGImage} loading={isLoading || showOGImageInfo}>
                Trigger OG image generation
              </Button>

              {showOGImageInfo && <p className="info-text">Generating OG image, it could take a few seconds...</p>}
            </div>
          )}

          {canRunWebflowUpsert && (
            <div className="action-item">
              <Button type="primary" onClick={handleModuleToWebflow} loading={isLoading}>
                Trigger Webflow import
              </Button>
            </div>
          )}

          {canSyncBetaTesters && (
            <>
              <div className="action-item">
                <Button type="primary" onClick={handleSyncBetaTesters} loading={isLoading}>
                  Sync beta testers
                </Button>
              </div>

              {!isLoading && betaTestersData && (
                <ul className="beta-testers-list">
                  <h5>Beta testers:</h5>

                  {betaTestersData?.with_groups?.map((t) => (
                    <li key={t}>
                      {t} - <span className="with-group">Has groups</span>
                    </li>
                  ))}

                  {betaTestersData?.without_groups?.map((t) => (
                    <li key={t}>
                      {t} - <span className="without-group">Does NOT have groups</span>
                    </li>
                  ))}

                  {betaTestersData?.not_found?.map((t) => (
                    <li key={t}>
                      {t} - <span className="not-found">User doesn't exist</span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      )}
    </Container>
  )
}

export default ToolbarActions
