import axios from 'axios'
import dayjs from 'dayjs'
import {
  SET_MODULE_LOADING,
  SET_MODULE_ATTEMPT_LOADING,
  SET_MODULE_CONTENT_STREAM_LOADING,
  // SET_MODULE_PREVIEW_LOADING,
  SET_MODULE_RATING_LOADING,
  SET_CATALOG_LOADING,
  FEATURED_MODULES_UPDATED,
  IN_PROGRESS_MODULES_UPDATED,
  FINISHED_MODULES_UPDATED,
  RELATED_MODULES_INFO_UPDATED,
  NEW_MODULES_UPDATED,
  MULTI_MODULES_PROGRESS_UPDATED,
  MODULE_UPDATED,
  MODULE_PREVIEW_UPDATED,
  MODULE_ATTEMPT_REMAINING_TIME_UPDATED,
  MODULE_ATTEMPT_SCORE_UPDATED,
  // MODULE_REPORT_UPDATED,
  MODULE_CONTENT_STREAM_UPDATED,
  MODULE_CONVERSATION_UPDATED,
  ALLOCATED_MODULES_UPDATED,
  MODULE_TOOLBAR_UPDATED,
  MODULE_BETA_TESTERS_UPDATED,
  PAGE_UPDATED,
  OBJECTIVE_UPDATED,
  SET_MODULE_TIMER_MODAL_AUTO_OPENED,
  SET_MODULE_TIMER_MODAL_OPEN,
  SET_RESET_MODULE_MODAL_OPEN,
  SET_RATING_MODAL_OPEN,
  SET_SHARING_MODAL_OPEN,
  SET_FINISHED_MODULE_MODAL_OPEN,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
  SET_MODULE_ATTEMPT_ERROR,
  SET_MODULE_PREVIEW_ERROR,
} from '@/store/modules'
import { LAB_UPDATED, SET_LAST_LAB_ACTION_DATE } from '@/store/labs'
import {
  fetchModules,
  fetchModule,
  patchModule,
  fetchRelatedModules,
  fetchNewModules,
  multiFetchModulesProgress,
  postModuleAttempt,
  postModuleAttemptStop,
  postModuleAttemptResume,
  postModuleAttemptExtendTime,
  getModuleAttemptContent,
  postModuleAttemptAction,
  getModuleAttemptScore,
  // fetchModuleReport,
  fetchAllocatedModules,
  fetchModuleToolbar,
  postModuleRating,
  patchModuleRating,
  fetchModuleConversation,
  postModuleConversation,
  putModuleConversation,
  deleteModuleConversation,
  postModuleImport,
  postModuleRegenerateOGImage,
  postModuleWebflowUpsert,
  postModulesSyncBetaTesters,
} from '@/services/modules'
import { startDeviceSession } from '@/store/labs/actions'
import { showToast } from '@/utils/toast'

const cleanModuleActivitiesStatus = (module) => {
  module.module_pages = module.module_pages.map((p) => {
    const auxPage = {
      ...p,
      activities: p.activities.map((a) => {
        const auxActivity = {
          ...a,
          user_status: null,
          hint_html: null,
          solution_html: null,
        }

        return auxActivity
      }),
    }
    return auxPage
  })
}

const getModules = (params, cb) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const modules = await fetchModules({ ...params, page_size: 'None' })

    if ('is_featured' in params) {
      dispatch(FEATURED_MODULES_UPDATED(modules))
    }

    if ('in_progress' in params) {
      dispatch(IN_PROGRESS_MODULES_UPDATED(modules))
    }

    if ('finished' in params) {
      dispatch(FINISHED_MODULES_UPDATED(modules))
    }

    if (cb) {
      cb(modules)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const getModule = (moduleId, cb) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const module = await fetchModule(moduleId)
    const moduleAttempt = module?.user_status?.last_module_attempt
    const previewHtmlUrl = moduleAttempt?.preview_html?.[0]?.preview_html

    dispatch(MODULE_UPDATED(module))
    dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(moduleAttempt?.remaining_time_in_secs))

    try {
      if (previewHtmlUrl) {
        const previewHtml = await axios({
          method: 'GET',
          url: previewHtmlUrl,
        })
        dispatch(MODULE_PREVIEW_UPDATED(previewHtml?.data))
      } else {
        dispatch(MODULE_PREVIEW_UPDATED(null))
      }
    } catch (error) {
      const { message } = error
      dispatch(SET_MODULE_PREVIEW_ERROR(message))
    }

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const updateModule = (moduleId, newData) => async (dispatch, getState) => {
  try {
    const { currentModule } = getState().modules

    await patchModule(moduleId, { ...newData })

    if (currentModule) {
      let auxModule = {
        ...currentModule,
        ...newData,
      }

      dispatch(MODULE_UPDATED(auxModule))
    }

    return
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return { error: message }
  }
}

const getRelatedModules = () => async (dispatch, getState) => {
  try {
    const { currentModule } = getState().modules

    const info = await fetchRelatedModules()
    const auxInfo = { ...info, related_modules: info?.related_modules?.filter((m) => m.id !== currentModule?.id) }

    dispatch(RELATED_MODULES_INFO_UPDATED(auxInfo))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const getNewModules = () => async (dispatch) => {
  try {
    const modules = await fetchNewModules()
    dispatch(NEW_MODULES_UPDATED(modules?.new_modules))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const getMultiModulesProgress = (moduleIds, cb) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const modulesProgress = await multiFetchModulesProgress(moduleIds)
    dispatch(MULTI_MODULES_PROGRESS_UPDATED(modulesProgress))

    if (cb) {
      cb(modulesProgress)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))

    if (cb) {
      cb()
    }
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const createModuleAttempt = (moduleId, body) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_ATTEMPT_LOADING(true))

    const { currentModule, allocatedModules } = getState().modules
    const { currentLab } = getState().labs
    const isAssessment = currentModule?.module_type === 'assessment'

    if (isAssessment) {
      dispatch(selectObjective(null))
      dispatch(selectPage(null))
      dispatch(MODULE_CONTENT_STREAM_UPDATED(null))
    }

    const response = await postModuleAttempt(moduleId, body)
    const moduleAttempt = response?.module_attempt
    const labSession = response?.lab_session

    let auxModule = {
      ...currentModule,
      user_status: {
        ...currentModule.user_status,
        last_module_attempt: moduleAttempt,
      },
    }
    cleanModuleActivitiesStatus(auxModule)

    dispatch(MODULE_UPDATED(auxModule))
    dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(moduleAttempt?.remaining_time_in_secs))

    if (labSession) {
      const auxLab = {
        ...currentLab,
        allocated_session: labSession,
      }

      dispatch(LAB_UPDATED(auxLab))

      const newAllocatedLab = {
        lab_id: labSession.lab,
        lab_session_id: labSession.id,
        lab_running_time: labSession.lab_running_time,
        module_id: currentModule?.id,
        module_name: currentModule.name,
        module_attempt_id: moduleAttempt?.id,
      }

      const auxAllocatedModules = allocatedModules ? [...allocatedModules, newAllocatedLab] : [newAllocatedLab]
      dispatch(ALLOCATED_MODULES_UPDATED(auxAllocatedModules))

      auxLab.allocated_session?.devices?.forEach((ds) => {
        dispatch(startDeviceSession(labSession?.id, ds.id))
      })
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ATTEMPT_ERROR(message))
  } finally {
    dispatch(SET_MODULE_ATTEMPT_LOADING(false))
  }
}

const stopModuleAttempt = (moduleId, moduleAttemptId, body) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_ATTEMPT_LOADING(true))

    const { currentModule, allocatedModules } = getState().modules
    const { currentLab } = getState().labs

    const updatedModuleAttempt = await postModuleAttemptStop(moduleId, moduleAttemptId, body)

    let auxModule = {
      ...currentModule,
      user_status: {
        ...currentModule.user_status,
        last_module_attempt: updatedModuleAttempt,
      },
      // remove old previews
      preview_html: [],
    }

    if (currentModule.id === updatedModuleAttempt.module) {
      dispatch(MODULE_UPDATED(auxModule))
      dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(updatedModuleAttempt?.remaining_time_in_secs))

      if (currentLab) {
        const auxLab = {
          ...currentLab,
          allocated_session: {
            ...currentLab?.allocated_session,
            status: 'deallocated',
            deallocate_reason: body?.reason || 'manual',
            devices: currentLab?.allocated_session?.devices?.map((d) => ({ ...d, status: 'stopping' })),
          },
          last_deallocated_session: {
            ...currentLab?.allocated_session,
            status: 'deallocated',
            deallocate_reason: body?.reason || 'manual',
          },
          last_commit: currentLab?.commit_user_image
            ? {
                created: dayjs().toISOString(),
              }
            : null,
        }
        dispatch(LAB_UPDATED(auxLab))
      }
    }

    const auxAllocatedModules = allocatedModules?.filter((m) => m.module_attempt_id !== moduleAttemptId)
    dispatch(ALLOCATED_MODULES_UPDATED(auxAllocatedModules))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    showToast(message || 'There was an error loading project content', 'error')
  } finally {
    dispatch(SET_MODULE_ATTEMPT_LOADING(false))
  }
}

const resumeModuleAttempt = (moduleId, moduleAttemptId, body) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_ATTEMPT_LOADING(true))

    const { currentModule, allocatedModules } = getState().modules
    const { currentLab } = getState().labs

    const response = await postModuleAttemptResume(moduleId, moduleAttemptId, body)
    const moduleAttempt = response?.module_attempt
    const labSession = response?.lab_session

    let auxModule = {
      ...currentModule,
      user_status: {
        ...currentModule.user_status,
        last_module_attempt: moduleAttempt,
      },
    }

    dispatch(MODULE_UPDATED(auxModule))
    dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(moduleAttempt?.remaining_time_in_secs))

    if (currentLab) {
      const auxLab = {
        ...currentLab,
        allocated_session: labSession,
      }

      dispatch(LAB_UPDATED(auxLab))

      const newAllocatedLab = {
        lab_id: labSession.lab,
        lab_session_id: labSession.id,
        lab_running_time: labSession.lab_running_time,
        module_id: currentModule?.id,
        module_name: currentModule.name,
        module_attempt_id: moduleAttempt?.id,
      }

      const auxAllocatedModules = allocatedModules ? [...allocatedModules, newAllocatedLab] : [newAllocatedLab]
      dispatch(ALLOCATED_MODULES_UPDATED(auxAllocatedModules))

      auxLab.allocated_session?.devices?.forEach((ds) => {
        dispatch(startDeviceSession(labSession?.id, ds.id))
      })
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    showToast(message || 'There was an error loading project content', 'error')
  } finally {
    dispatch(SET_MODULE_ATTEMPT_LOADING(false))
  }
}

const extendModuleAttempt = (moduleId, moduleAttemptId, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_ATTEMPT_LOADING(true))

    const { currentModule } = getState().modules

    const extendedModuleAttempt = await postModuleAttemptExtendTime(moduleId, moduleAttemptId)

    let auxModule = {
      ...currentModule,
      user_status: {
        ...currentModule.user_status,
        last_module_attempt: extendedModuleAttempt,
      },
    }
    dispatch(MODULE_UPDATED(auxModule))
    dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(extendedModuleAttempt?.remaining_time_in_secs))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    showToast(message || 'There was an error loading project content', 'error')
  } finally {
    dispatch(SET_MODULE_ATTEMPT_LOADING(false))
  }
}

const fetchModuleAttemptContent = (moduleId, moduleAttemptId) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(true))

    const content = await getModuleAttemptContent(moduleId, moduleAttemptId)
    dispatch(MODULE_CONTENT_STREAM_UPDATED(content))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(false))
  }
}

const createModuleAttemptAction = (moduleId, moduleAttemptId, data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(true))

    const { moduleContentStream } = getState().modules

    const newContent = await postModuleAttemptAction(moduleId, moduleAttemptId, data)

    let auxModuleContentStream = moduleContentStream?.map((content) => {
      // actioned content
      if (content.id === data?.content_id) {
        return {
          ...content,
          actions: [...content.actions, { actioned_at: dayjs().toISOString(), type: data?.action_type }],
        }
      }

      // upcoming content
      if (content.id === newContent.id) {
        return newContent
      }
      return content
    })

    dispatch(MODULE_CONTENT_STREAM_UPDATED(auxModuleContentStream))

    if (cb) {
      cb(auxModuleContentStream)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(false))
  }
}

const skipAssessmentContent = (actionType, contentId) => async (dispatch, getState) => {
  const { currentModule, moduleContentStream, currentObjective, currentPage } = getState().modules

  const data = {
    content_id: contentId,
    action_type: actionType,
  }

  await dispatch(
    createModuleAttemptAction(currentModule?.id, currentModule?.user_status?.last_module_attempt?.id, data, () => {
      let groupedPages = {}
      moduleContentStream?.forEach((content) => {
        if (groupedPages[content?.objective]) {
          groupedPages[content?.objective].push(content)
        } else {
          groupedPages[content?.objective] = [content]
        }
      })

      const objectives = Object.keys(groupedPages)

      if (!groupedPages?.[currentObjective]?.[currentPage + 1]) {
        const currentObjectiveIndex = objectives.indexOf(currentObjective)
        const nextObjective = objectives[currentObjectiveIndex + 1]

        if (!nextObjective) {
          dispatch(
            stopModuleAttempt(currentModule?.id, currentModule?.user_status?.last_module_attempt?.id, {
              reason: 'manual',
            }),
          )
          return
        }

        dispatch(selectObjective(nextObjective))
        dispatch(selectPage(0))
        return
      }

      dispatch(selectPage(currentPage + 1))
    }),
  )
}

const fetchModuleAttemptScore = (moduleId, moduleAttemptId) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(true))

    const content = await getModuleAttemptScore(moduleId, moduleAttemptId)
    dispatch(MODULE_ATTEMPT_SCORE_UPDATED(content))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_CONTENT_STREAM_LOADING(false))
  }
}

// const getModuleReport = (moduleId, moduleAttemptId) => async (dispatch) => {
//   try {
//     const module = await fetchModuleReport(moduleId, moduleAttemptId)
//     dispatch(MODULE_REPORT_UPDATED(module))
//   } catch (error) {
//     const { message } = error
//     dispatch(SET_MODULE_ERROR(message))
//   }
// }

const getAllocatedModules = () => async (dispatch) => {
  try {
    const module = await fetchAllocatedModules()
    dispatch(ALLOCATED_MODULES_UPDATED(module))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const getModuleToolbar = (moduleId) => async (dispatch) => {
  try {
    const toolbarData = await fetchModuleToolbar(moduleId)
    dispatch(MODULE_TOOLBAR_UPDATED(toolbarData))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const createModuleRating = (moduleId, ratingData) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_RATING_LOADING(true))

    const { currentModule } = getState().modules

    const rating = await postModuleRating(moduleId, ratingData)

    if (currentModule) {
      let auxModule = {
        ...currentModule,
        user_status: {
          ...currentModule.user_status,
          module_rating: rating,
        },
      }

      dispatch(MODULE_UPDATED(auxModule))
    }

    showToast('Your rating was submitted')
    return rating
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  } finally {
    dispatch(SET_MODULE_RATING_LOADING(false))
  }
}

const updateModuleRating = (moduleId, ratingData) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_RATING_LOADING(true))

    const { currentModule } = getState().modules
    const scoring = currentModule?.user_status?.last_module_attempt?.user_status?.scoring

    const rating = await patchModuleRating(moduleId, { ...ratingData, user_score: scoring })

    if (currentModule) {
      let auxModule = {
        ...currentModule,
        user_status: {
          ...currentModule.user_status,
          module_rating: rating,
        },
      }

      dispatch(MODULE_UPDATED(auxModule))
    }

    showToast('Your rating was updated')
    return rating
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  } finally {
    dispatch(SET_MODULE_RATING_LOADING(false))
  }
}

const getModuleConversation = (moduleId, conversationId) => async (dispatch) => {
  try {
    const conversation = await fetchModuleConversation(moduleId, conversationId)
    dispatch(MODULE_CONVERSATION_UPDATED(conversation))
    return
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const createModuleConversation = (moduleId) => async (dispatch) => {
  try {
    const conversation = await postModuleConversation(moduleId)
    dispatch(MODULE_CONVERSATION_UPDATED(conversation))

    return conversation.id
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  }
}

const addUserMessageToModuleConversation = (message) => (dispatch, getState) => {
  try {
    const { moduleConversation } = getState().modules

    const auxMessage = { date: dayjs().format(), role: 'user', content: message }

    let auxConversation = {
      ...moduleConversation,
      messages: [...moduleConversation?.messages, auxMessage],
    }

    dispatch(MODULE_CONVERSATION_UPDATED(auxConversation))
    return
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  }
}

const updateModuleConversation = (moduleId, conversationId, message) => async (dispatch, getState) => {
  try {
    const { moduleConversation } = getState().modules

    const response = await putModuleConversation(moduleId, conversationId, message)
    const auxMessage = { date: dayjs().format(), role: 'assistant', content_html: response?.content }

    let auxConversation = {
      ...moduleConversation,
      messages: [...moduleConversation?.messages, auxMessage],
    }

    dispatch(MODULE_CONVERSATION_UPDATED(auxConversation))
    return
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  }
}

const removeModuleConversation = (moduleId, conversationId) => async (dispatch, getState) => {
  try {
    const { currentModule } = getState().modules

    await deleteModuleConversation(moduleId, conversationId)

    let auxModule = {
      ...currentModule,
      user_status: { ...currentModule?.user_status, chat_conversation: null },
    }

    dispatch(MODULE_UPDATED(auxModule))
    dispatch(MODULE_CONVERSATION_UPDATED(null))
    return
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
    return
  }
}

const importModule = (githubUrl) => async (dispatch, getState) => {
  try {
    const { currentModule } = getState().modules

    const response = await postModuleImport({ github_project_url: githubUrl })

    if (currentModule) {
      let auxModule = {
        ...currentModule,
        last_imported_at: dayjs().toISOString(),
      }

      dispatch(MODULE_UPDATED(auxModule))
    }
    showToast('Project successfully imported')

    return response
  } catch (error) {
    return { success: false, error: error?.message }
  }
}

const regenerateOGImage = (moduleId) => async () => {
  try {
    await postModuleRegenerateOGImage(moduleId)
    showToast('OG image successfully regenerated')

    return { success: true }
  } catch (error) {
    return { success: false, error: error?.message }
  }
}

const importModuleToWebflow = (moduleId) => async () => {
  try {
    const response = await postModuleWebflowUpsert(moduleId)
    showToast('Module successfully imported to Webflow')

    return response
  } catch (error) {
    return { success: false, error: error?.message }
  }
}

const syncModuleBetaTesters = () => async (dispatch) => {
  try {
    const response = await postModulesSyncBetaTesters()
    dispatch(MODULE_BETA_TESTERS_UPDATED(response))

    return response
  } catch (error) {
    return { success: false, error: error?.message }
  }
}

const setAttemptRemainingTime = (remainingTime) => (dispatch) => {
  dispatch(MODULE_ATTEMPT_REMAINING_TIME_UPDATED(remainingTime))
}

const selectPage = (newPage) => (dispatch, getState) => {
  const { currentLab } = getState().labs

  dispatch(PAGE_UPDATED(newPage))

  if (currentLab?.allocated_session?.status === 'allocated') {
    dispatch(SET_LAST_LAB_ACTION_DATE(dayjs().toISOString()))
  }
}

const selectObjective = (newObjective) => (dispatch) => {
  dispatch(OBJECTIVE_UPDATED(newObjective))
}

const setModuleTimerModalAutoOpened = (isAutoOpened) => (dispatch) => {
  dispatch(SET_MODULE_TIMER_MODAL_AUTO_OPENED(isAutoOpened))
}

const setModuleTimerModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_MODULE_TIMER_MODAL_OPEN(isOpen))
}

const setResetModuleModalOpen = (payload) => (dispatch) => {
  dispatch(SET_RESET_MODULE_MODAL_OPEN(payload))
}

const setRatingModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_RATING_MODAL_OPEN(isOpen))
}

const setSharingModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_SHARING_MODAL_OPEN(isOpen))
}

const setFinishedModuleModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_FINISHED_MODULE_MODAL_OPEN(isOpen))
}

const setCatalogIsLoading = (isLoading) => (dispatch) => {
  dispatch(SET_CATALOG_LOADING(isLoading))
}

const resetModulesState = () => (dispatch) => {
  dispatch(RESET_MODULES_STATE())
}

export {
  getModules,
  getModule,
  updateModule,
  getRelatedModules,
  getNewModules,
  getMultiModulesProgress,
  createModuleAttempt,
  stopModuleAttempt,
  resumeModuleAttempt,
  extendModuleAttempt,
  fetchModuleAttemptContent,
  createModuleAttemptAction,
  skipAssessmentContent,
  fetchModuleAttemptScore,
  // getModuleReport,
  getAllocatedModules,
  getModuleToolbar,
  createModuleRating,
  updateModuleRating,
  getModuleConversation,
  createModuleConversation,
  addUserMessageToModuleConversation,
  updateModuleConversation,
  removeModuleConversation,
  importModule,
  regenerateOGImage,
  importModuleToWebflow,
  syncModuleBetaTesters,
  setAttemptRemainingTime,
  selectPage,
  selectObjective,
  setModuleTimerModalAutoOpened,
  setModuleTimerModalOpen,
  setResetModuleModalOpen,
  setRatingModalOpen,
  setSharingModalOpen,
  setFinishedModuleModalOpen,
  setCatalogIsLoading,
  resetModulesState,
}
